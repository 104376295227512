import React,{useEffect, useState, Component} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Data from '../data/data.json'
import Noimage from '../components/body/picture_test/noimage.png'
import './stylescomponent.scss';
import ShareIcon from '@mui/icons-material/Share';
import TurnedInIcon from '@mui/icons-material/TurnedIn';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';



function MainArticles(){

var articles=Data.articles
let arrayArticles=[]

articles.forEach((items)=>{
  arrayArticles.push(items)
})

arrayArticles.splice(0,7)
arrayArticles.splice(7,6)



arrayArticles.forEach((item)=>{

  if(item.urlToImage==null)
  item.urlToImage=Noimage

  if(item.title==null)
  item.title="Der Titel wird zeitnah zur Verfügung gestellt"

  if(item.description==null)
    item.description="Der Inhalt wird zeitnah zur Verfügung gestellt"
   
    if(item.author==null)
    item.author="Handelsblatt Journalisten"
})


  

    return(
      <div>
        <div className='MainArticles' >
          <div className='MainImg'>
              <img className='Img' src={arrayArticles[0].urlToImage}/>
          </div>

          <div className='MainTitle'>
              {arrayArticles[0].title}
          </div>
          <div className='MainContent'>
            {arrayArticles[0].description}
          <span style={{color: 'orange', marginLeft: '5px'}}>Mehr...</span>
                <br></br>
               <div style={{color:'gray', marginTop:10, fontStyle:'oblique'}}>Von {arrayArticles[0].author}</div>
               <div className='ArticleIcons'>
<div className='IconandText'><ShareIcon style={{color:'grey', fontSize:'15px'}}/>Teilen</div>
<div className='IconandText'><TurnedInIcon style={{color:'grey', fontSize:'15px'}}/>Merken</div>
<div className='IconandText'><ChatBubbleIcon style={{color:'grey', fontSize:'15px'}}/>1 Kommentar</div>
</div>
          </div>
       
          <div style={{width:"77%", height:'55px'}}></div>
          <hr width="100%" style={{marginRight:'100%'}}></hr>
             <div className='SmallArticle' >
                <div className='ImgSmallArticle'>
                <img className='ImgSmall' src={arrayArticles[1].urlToImage}/>
                </div>

                <div className='TitleandContent'>
                <div className='SmallMainTitle'>
              {arrayArticles[1].title}
          </div>
          <div className='SmallMainContent'>
            {arrayArticles[1].description}
          <span style={{color: 'orange', marginLeft: '5px'}}>Mehr...</span>
                <br></br>
               <div style={{color:'gray', marginTop:10, fontStyle:'oblique'}}>Von {arrayArticles[1].author}</div>
               <div className='ArticleIcons'>
<div className='IconandText'><ShareIcon style={{color:'grey', fontSize:'15px'}}/>Teilen</div>
<div className='IconandText'><TurnedInIcon style={{color:'grey', fontSize:'15px'}}/>Merken</div>
<div className='IconandText'><ChatBubbleIcon style={{color:'grey', fontSize:'15px'}}/>1 Kommentar</div>
</div>
          </div>

          </div>
             </div>

             <hr width="100%" style={{marginRight:'100%'}}></hr>

             <div className='SmallArticle'>
             <div className='ImgSmallArticle'>
                  <img className='ImgSmall' src={arrayArticles[2].urlToImage}/>
              </div>
              <div className='TitleandContent'>
              <div className='SmallMainTitle'>
              {arrayArticles[2].title}
          </div>
          <div className='SmallMainContent'>
            {arrayArticles[2].description}
          <span style={{color: 'orange', marginLeft: '5px'}}>Mehr...</span>
                <br></br>
               <div style={{color:'gray', marginTop:10, fontStyle:'oblique'}}>Von {arrayArticles[2].author}</div>
               <div className='ArticleIcons'>
<div className='IconandText'><ShareIcon style={{color:'grey', fontSize:'15px'}}/>Teilen</div>
<div className='IconandText'><TurnedInIcon style={{color:'grey', fontSize:'15px'}}/>Merken</div>
<div className='IconandText'><ChatBubbleIcon style={{color:'grey', fontSize:'15px'}}/>1 Kommentar</div>
</div>
          </div>
         
          <div style={{width:"77%", height:'55px'}}></div>
          </div>
         
             </div>
             <hr width="100%" style={{marginRight:'100%'}}></hr>
        </div>



        <div className='MainArticles' >
          <div className='MainImg'>
              <img className='Img' src={arrayArticles[3].urlToImage}/>
          </div>

          <div className='MainTitle'>
              {arrayArticles[3].title}
          </div>
          <div className='MainContent'>
            {arrayArticles[3].description}
          <span style={{color: 'orange', marginLeft: '5px'}}>Mehr...</span>
                <br></br>
               <div style={{color:'gray', marginTop:10, fontStyle:'oblique'}}>Von {arrayArticles[3].author}</div>
               <div className='ArticleIcons'>
<div className='IconandText'><ShareIcon style={{color:'grey', fontSize:'15px'}}/>Teilen</div>
<div className='IconandText'><TurnedInIcon style={{color:'grey', fontSize:'15px'}}/>Merken</div>
<div className='IconandText'><ChatBubbleIcon style={{color:'grey', fontSize:'15px'}}/>1 Kommentar</div>
</div>
          </div>
       
          <div style={{width:"77%", height:'55px'}}></div>
          <hr width="100%" style={{marginRight:'100%'}}></hr>
             <div className='SmallArticle' >
                <div className='ImgSmallArticle'>
                <img className='ImgSmall' src={arrayArticles[4].urlToImage}/>
                </div>

                <div className='TitleandContent'>
                <div className='SmallMainTitle'>
              {arrayArticles[4].title}
          </div>
          <div className='SmallMainContent'>
            {arrayArticles[4].description}
          <span style={{color: 'orange', marginLeft: '5px'}}>Mehr...</span>
                <br></br>
               <div style={{color:'gray', marginTop:10, fontStyle:'oblique'}}>Von {arrayArticles[4].author}</div>
               <div className='ArticleIcons'>
<div className='IconandText'><ShareIcon style={{color:'grey', fontSize:'15px'}}/>Teilen</div>
<div className='IconandText'><TurnedInIcon style={{color:'grey', fontSize:'15px'}}/>Merken</div>
<div className='IconandText'><ChatBubbleIcon style={{color:'grey', fontSize:'15px'}}/>1 Kommentar</div>
</div>
          </div>

          </div>
             </div>

             <hr width="100%" style={{marginRight:'100%'}}></hr>

             <div className='SmallArticle'>
             <div className='ImgSmallArticle'>
                  <img className='ImgSmall' src={arrayArticles[5].urlToImage}/>
              </div>
              <div className='TitleandContent'>
              <div className='SmallMainTitle'>
              {arrayArticles[5].title}
          </div>
          <div className='SmallMainContent'>
            {arrayArticles[5].description}
          <span style={{color: 'orange', marginLeft: '5px'}}>Mehr...</span>
                <br></br>
               <div style={{color:'gray', marginTop:10, fontStyle:'oblique'}}>Von {arrayArticles[5].author}</div>
               <div className='ArticleIcons'>
<div className='IconandText'><ShareIcon style={{color:'grey', fontSize:'15px'}}/>Teilen</div>
<div className='IconandText'><TurnedInIcon style={{color:'grey', fontSize:'15px'}}/>Merken</div>
<div className='IconandText'><ChatBubbleIcon style={{color:'grey', fontSize:'15px'}}/>1 Kommentar</div>
</div>
          </div>
         
          <div style={{width:"77%", height:'55px'}}></div>
          </div>
         
             </div>
             <hr width="100%" style={{marginRight:'100%'}}></hr>
        </div>


        <div className='MainArticles' >
          <div className='MainImg'>
              <img className='Img' src={arrayArticles[6].urlToImage}/>
          </div>

          <div className='MainTitle'>
              {arrayArticles[6].title}
          </div>
          <div className='MainContent'>
            {arrayArticles[6].description}
          <span style={{color: 'orange', marginLeft: '5px'}}>Mehr...</span>
                <br></br>
               <div style={{color:'gray', marginTop:10, fontStyle:'oblique'}}>Von {arrayArticles[6].author}</div>
               <div className='ArticleIcons'>
<div className='IconandText'><ShareIcon style={{color:'grey', fontSize:'15px'}}/>Teilen</div>
<div className='IconandText'><TurnedInIcon style={{color:'grey', fontSize:'15px'}}/>Merken</div>
<div className='IconandText'><ChatBubbleIcon style={{color:'grey', fontSize:'15px'}}/>1 Kommentar</div>
</div>
          </div>

          </div>

          

        </div>
    )

   




}

export default MainArticles