import React from 'react'

import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import Test from './picture_test/test.jpg'
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import ShareIcon from '@material-ui/icons/Share';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import Mensch1 from './picture_test/Mensch_1.jpg'
import Mensch2 from './picture_test/Mensch_2.jpg'
import Firmen from './picture_test/firmen.tv.webp'
import Gastro from './picture_test/gastrotipps.webp'
import Partner from './picture_test/partner.webp'
import Beratung from './picture_test/beratung.de.webp'
import Ifram from './picture_test/ifram.jpg'
import  './Body.css'
import Data from '../../data/data2.json'
import Noimage from './picture_test/noimage.png'
import Pic from './picture_test/1.png'

const useStyles = makeStyles((theme) => ({
    root: {
      '& > * + *': {
       
        marginLeft: theme.spacing(2),
        display:'flex',
        flexDirection:'column'
         
      },
      
    }, 
    picture:{
     
      marginTop:20,
      cursor:'pointer'
     
  },
  

  }));


  var articles=Data.articles
  let arrayArticles=[]

  articles.forEach((items)=>{
  arrayArticles.push(items)
})


arrayArticles.forEach((item)=>{
  
  if(item.urlToImage==null)
  item.urlToImage=Noimage

  if(item.title==null)
  item.title="Der Titel wird zeitnah zur Verfügung gestellt"

  if(item.description==null)
    item.description="Der Inhalt wird zeitnah zur Verfügung gestellt"

    if(item.content==null)
    item.content="Der Inhalt wird zeitnah zur Verfügung gestellt"
   
    if(item.author==null)
    item.author="Handelsblatt Journalisten"
})



  export default function SideBar() {
    const classes = useStyles();
      return (
          <div className="SideBar">

            
              <div className="Newsticker">

              <div className="SidebarPicker">

                <div className="HeadWords">
                  <div style={{textTransform:'uppercase'}}>newsticker</div>
                  <div style={{textTransform:'uppercase'}}>märkte</div>
                </div>

                <div></div>
              
              </div>
              

              <div className="Textblock">

              <p className="Text">{arrayArticles[0].title}</p>
             

              </div>
              <hr width="96%">
              </hr>


              <div className="Textblock">

            <p className="Text">{arrayArticles[1].title}</p>


              </div>
              <hr width="96%">
              </hr>

              <div className="Textblock">

            <p className="Text">{arrayArticles[2].title}</p>


              </div>
              <hr width="96%">
              </hr>

              <div className="Textblock">

            <p className="Text">{arrayArticles[3].title}</p>


              </div>
              <hr width="96%">
              </hr>

              <div className="Textblock">

            <p className="Text">{arrayArticles[4].title}</p>


              </div>
              <hr width="96%">
              </hr>


              <div className="Textblock">

            <p className="Text">{arrayArticles[5].title}</p>


              </div>
              <hr width="96%">
              </hr>


              <div className="Textblock">

            <p className="Text">{arrayArticles[6].title}</p>


              </div>
              <hr width="96%">
              </hr>


              <div className="Textblock">

            <p className="Text">{arrayArticles[7].title}</p>


              </div>
              <hr width="96%">
              </hr>


              <div className="Textblock">

            <p className="Text">{arrayArticles[8].title}</p>


              </div>
              <hr width="96%">
              </hr>


              <div className="Textblock">

            <p className="Text">{arrayArticles[9].title}</p>


              </div>
              <hr width="96%">
              </hr>


              <div className="Textblock">

            <p className="Text">{arrayArticles[10].title}</p>


              </div>
              <hr width="96%">
              </hr>


              
              </div>
              
              <div className="Newsticker">

              <div className="SidebarPicker2">

              <div className="HeadWords2">   
              <p>Aktuell</p>

 
            </div>

            <img className="SidebarImg" src={arrayArticles[11].urlToImage} alt="logo" />

            <div className="Textblock">
            <p className="Text2">{arrayArticles[11].title}</p>
            <p className="Text3">{arrayArticles[11].description}</p>
            </div>
            
            <img className="SidebarImg" src={arrayArticles[12].urlToImage} alt="logo" />

            <div className="Textblock">
            <p className="Text2">{arrayArticles[12].title}</p>
            <p className="Text3">{arrayArticles[12].description}</p>
            </div>
            

            </div>
             

              </div>


             
              <div className="Newsticker" style={{overflowY:'hidden'}}>
              <iframe className="ElectionFrame"  src="https://hbpulse.azurefd.net/widgets/hb/overview-1/index.html?mode=small"></iframe>
              </div>


              <div className="Newsticker2" style={{overflowY:'hidden'}}>
              <iframe className="ElectionFrame" src="https://datawrapper.dwcdn.net/WKmCN/24/"></iframe>
              </div>

              <div className="Newsticker3" style={{overflowY:'hidden'}}>
              <img className="ElectionFrame"  src={Pic}/>
              </div>
            
 
 
 


              <div className="Smallnewsticker">

              <div className="SidebarPicker">

              <div className="HeadWords2">   
              <p>Partnerangebote </p>

              </div>


              </div>

            
              <img className={classes.picture} style={{marginLeft:8}} src={Firmen} alt="logo" />
              <img className={classes.picture} style={{marginLeft:8}} src={Gastro} alt="logo" />
              <img className={classes.picture} style={{marginLeft:8}} src={Partner} alt="logo" />
              <img className={classes.picture} style={{marginLeft:8}} src={Beratung} alt="logo" />
             </div>
             
          </div>
      )
  }
  