
import React,{useEffect, useState, Component} from 'react';
import './App.css';
// Handelsblatt Version
import { BrowserRouter as Router,Route, Switch} from 'react-router-dom';
import axios from 'axios';
import { withStyles } from '@material-ui/core/styles';
import Moment from 'moment'
import DoubleSide from './DoubleSide';
import Interscroller from './Interscroller';
import Interscroller2 from './Interscroller2';
import Rollover from './Rollover';
import Sidebar from './Sidebar';
import Takeover from './Takeover';
import Link from '@material-ui/core/Link';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';




const styles  = theme=> ({
  root: {
     
    maxWidth:1020, //1020
    backgroundColor:'white',
    
    
    
    '@media (max-width: 480px)': {
      
     }
    
  },

  line:{
    color:'#b9b9b9',
    width:'100%',
    '@media (max-width: 768px)': {
      display:'none'
    }

  },
  minilogo:{
    width:21,
    height:21
  },

  test:{
    position:'sticky'
  }
});




 class App extends React.Component {
  
  constructor(props) {  
  super(props);   
 this.state = {  
   footer:'none',
   billboard:'none',
   unicredit:'none',
   dopelside:'none',
   sidebar:'none',
   mobileInterscroller:'none',
   MobileTakeover:'none',
   
   rectangle:'none',
  //date: new Date().toLocaleString()
  date: Moment().format('LLL'),
 };}

 componentDidMount(){
  document.title = `Handelscheck`;
}

  render(){

  
  
  
  
 
  
  return (
  

    
    <div>


    <div id="buttons" className="Name">
   
    <p>Werbeformen auswählen</p>
      
    
   


   

<div className='Butto'>
    <ButtonGroup
        orientation="vertical"
        color="primary"
        aria-label="vertical contained primary button group"
        variant="contained"
        
      >
        <Button disabled={true} href="/#">DoubleSide</Button>
        <Button disabled={true} href="/#">Interscroller</Button>
        <Button disabled={true} href="/#">Rollover</Button>
        <Button disabled={true} href="/#">Sidebar</Button>
        <Button href="/takeover">Takeover</Button>
        <Button disabled={true} href="/#">Interscroller Muenchen</Button>
      </ButtonGroup>
    
      </div>

      <div className='Texto'>
      <div className="Bottom">© 2023 annalect. A brand of Omnicom Media Group. All Rights reserved</div>
      <div className="Impressum"><a href='https://www.annalect.de/impressum/'>Impressum</a></div>
      </div>
    </div>
    

    
    <Router>

      <div>
       
     
     <Switch>
      <Route path="/DoubleSide">
      <DoubleSide/>
      </Route>

      <Route path="/Interscroller">
      <Interscroller/>
      </Route>

      <Route path="/Interscroller2">
      <Interscroller2/>
      </Route>

      <Route path="/Rollover">
      <Rollover/>
      </Route>

      <Route path="/Sidebar">
      <Sidebar/>
      </Route>

      <Route path="/takeover">
      <Takeover/>
      </Route>

     </Switch>

     </div>

     </Router>

    </div>
 
  );}

  
  
}


export default withStyles(styles)(App);