import React,{useEffect, useState, Component} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Data from '../data/data.json'
import Noimage from '../components/body/picture_test/noimage.png'
import './stylescomponent.scss';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import SearchIcon from '@mui/icons-material/Search';
import PersonIcon from '@mui/icons-material/Person';



function HeadScroll(){

    window.addEventListener('scroll', function(){
        if(window.scrollY>200){
     
            document.getElementById("headscroll").style.transform="translateY(0px)"
            document.getElementById("headscroll").style.display="flex"
 
            

        }

        if(window.scrollY<200){
              document.getElementById("headscroll").style.transform="translateY(-200px)"
              document.getElementById("headscroll").style.display="none"
          }
    })

    return(
        <div className='HeadScroll' id='headscroll'>
        <div className='MenuHome'>
            <MenuIcon sx={{width: '30px', height: '30px', background: 'gray', borderRadius: '50%',color: 'white'}}/>
            <HomeIcon sx={{width: '30px', height: '30px', background: 'gray', borderRadius: '50%',color: 'white'}}/>
        </div>
        <div className='ImgHead'>
            <img src="https://www.handelsblatt.com/images/NK_RK_Premium_Header_Sticky_200x71/28934094/2-formatOriginal.png"/>
        </div>
        <div className='SocialMedia'>
            <FacebookIcon sx={{width: '30px', height: '30px', background: '#3664a2', borderRadius: '50%',color: 'white'}}/>
            <TwitterIcon sx={{width: '30px', height: '30px', background: 'skyblue', borderRadius: '50%',color: 'white'}}/>
            <LinkedInIcon sx={{width: '30px', height: '30px', background: '#0a76b4', borderRadius: '50%',color: 'white'}}/>
        </div>
        <div className='PersonSearch'>
            <SearchIcon sx={{width: '30px', height: '30px', background: 'gray', borderRadius: '50%',color: 'white'}}/>
            <PersonIcon sx={{width: '30px', height: '30px', background: 'gray', borderRadius: '50%',color: 'white'}}/>

        </div>
        </div>

  
    )




}

export default HeadScroll