import React,{useEffect, useState, Component} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Data from '../data/data.json'
import Noimage from '../components/body/picture_test/noimage.png'
import './stylescomponent.scss';




function ThreeArticles2(){

var articles=Data.articles
let arrayArticles=[]

articles.forEach((items)=>{
  arrayArticles.push(items)
})

arrayArticles.splice(0,4)
arrayArticles.splice(3,16)


return(arrayArticles.map((item,index)=>{

    if(item.urlToImage==null)
    item.urlToImage=Noimage
  
    if(item.title==null)
    item.title="Der Titel wird zeitnah zur Verfügung gestellt"
  
    if(item.description==null)
      item.description="Der Inhalt wird zeitnah zur Verfügung gestellt"
     
      if(item.author==null)
      item.author="den Handelsblatt Journalisten"



    return(
        <div className='threeArticles' key={index}>
            <div className='Img'>
                <img className='thrArtcImg' src={item.urlToImage}/>
            </div>
            <div className='Title'>
                {item.title}
            </div>

            <div className='Desription'>
                {item.description}
                <span style={{color: 'orange', marginLeft: '5px'}}>Mehr...</span>
                <br></br>
                <div style={{color:'gray', marginTop:10, fontStyle:'oblique'}}>Von {item.author}</div>
            </div>

        </div>
    )



}))


}

export default ThreeArticles2