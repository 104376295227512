import React,{useEffect, useState, Component} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Data from '../data/data.json'
import Noimage from '../components/body/picture_test/noimage.png'
import './stylescomponent.scss';




function Links(){




  

    return(
        <div className='Links'>
            <div className='Column'>
                <div className='Headline'>Übersicht</div>
                <div>Meine News</div>
                <div>Home</div>
                <div>Politik</div>
                <div>Unternehmen</div>
                <div>Technologie</div>
                <div>Finanzen</div>
                <div>Mobilität</div>
                <div>Karriere</div>
                <div>Arts & Style</div>
                <div>Meinung</div>
                <div>Video</div>
                <div>Service</div>
            </div>
            <div className='Column'>
            <div className='Headline'>Service</div>
                <div>Abo kündigen</div>
                <div>Kontakt/Hilfe</div>
                <div>Online-Archiv</div>
                <div>Veranstaltungen</div>
                <div>Handelsblatt-Shop</div>
                <div>Netiquette</div>
                <div>Sitemap</div>
                <div>Nutzungsrechte erwerben</div>
                <div>AGB</div>
                <div>Datenschutzerkärung</div>
                <div>Datenschutzeinstellungen</div>
                <div>Impressum</div>
            </div>
            <div className='Column'>
            <div className='Headline'>Links</div>
            <div>WirtschaftsWoche</div>
            <div>Absatzwirtschaft</div>
            <div>Morning Briefing</div>
            <div>Finanzvergleiche</div>
            <div>GBI-Genios</div>
            <div>PMG</div>
            <div>Kredikarten-Vergleich</div>
            <div>Infoseiten</div>
            <div>Bellevue Ferienhaus</div>
            <div>Facebook</div>
            <div>Twitter</div>
            <div>plipboard</div>
            </div>
            <div className='Column' id="column">
            <div>karriere.de</div>
            <div>iq digital</div>
            <div>Research Institute</div>
            <div>Organisations-Entwicklung</div>
            <div>Crediteform</div>
            <div>Fair Company Iniative</div>
            <div>Sudoku online spielen</div>
            <div>Brutto-Netto-Rechner</div>
            <div>Geschäftskonto-vergleich</div>
            <div>Handelsblatt Studium</div>
            <div>Handelsblatt Coupons</div>
           
            </div>

        </div>
    )

   



}

export default Links