import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import './Navbar.css';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { format } from 'date-fns'
import { de } from 'date-fns/locale/'
import Typography from '@material-ui/core/Typography';
import { CenterFocusStrong } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > * + *': {
      marginLeft: theme.spacing(2),
      color: '#646464',
      
       fontSize:12,
     width:'100%',
       '@media (max-width: 768px)': {
        fontSize:9,
       }
       
    },
   
  },
  line:{
    color:'#b9b9b9'

  },


  date:{
    color: '#646464',
   // fontSize:'0.7vw', //12
   fontSize:10,
  },

  test:{
    
  },

  abo:{
    backgroundColor:'#ef7100',
    float:'right',
    color:'white',
    width:40,
   
    
  },
  login:{
    
    float:'right',
    
    width:40,
   
    
  }
}));

function Navbar(){
  const date = new Date();
  const locales = {de}


  return (
    <div className="Nav">
      <div className='Date'>
      {format(date, 'EEEE, d, MMMM yyyy',{locale: de})}
      </div>
      <div className='Category'>
        <div id="Newsletter">Newsletter</div>
        <div id="Podcasts">Podcasts</div>
        <div id="Club">Club</div>
        <div id="ePaper">ePaper</div>
        <div id="Archiv">Archiv</div>
        <div id="Shop">Shop</div>
        <div id="Jobs">Jobs</div>
        <div id="Inside">Inside</div>
      </div>
      <div className='Buttons'>
        <div className='LoginBtn'>Login</div>
        <div className='Abo'>Abo</div>
      </div>
  
  </div>

  );
  
}

export default Navbar