import logo from './logo.svg';
import React,{useEffect, useState, Component} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import './App.css';
import './style.scss';
// Handelsblatt Version

import Navbar from './components/header/Navbar.js'
import DataNew from './data/data.json'
import Buttonsgroup from './components/body/Buttonsgroup'
import Navbarfoot from './components/header/Navbarfoot.js'
import mainlogo from './logos/handelsblatt.png'
import ShareIcon from '@mui/icons-material/Share';
import TurnedInIcon from '@mui/icons-material/TurnedIn';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import Grid from '@material-ui/core/Grid';
import noimage from './logos/noimage.png'
import SideBar from './components/body/SideBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import MenuIcon from '@material-ui/icons/Menu';
import 'moment/locale/de'
import './script.js'
import ThreeArticles from './componentsnew/ThreeArticles.jsx';
import ThreeArticles2 from './componentsnew/ThreeArticles2.jsx';
import ThreeArticles3 from './componentsnew/ThreeArticles3.jsx';
import ThreeArticles4 from './componentsnew/ThreeArticles4.jsx';
import MainArticles from './componentsnew/MainArticles.jsx';
import HeadScroll from './componentsnew/HeadScroll.jsx';
import Links from './componentsnew/Links.jsx';
import Footer from './components/header/Footer.js';


// Werbemittel 





const styles  = theme=> ({
  root: {
     
    maxWidth:1020, //1020
    backgroundColor:'white',
    
    
    
    '@media (max-width: 480px)': {
      
     }
    
  },

  line:{
    color:'#b9b9b9',
    width:'100%',
    '@media (max-width: 768px)': {
      display:'none'
    }

  },


 
});


const useStyles = makeStyles((theme) => ({


}));


 function Takeover(){
  
  const classes = useStyles();
  
 console.log(DataNew)


 useEffect(()=>{
  document.title = `Handelscheck`;
 },[])
  
  return (
  

    
    <div className="Backscreen">
     
       
      

  
     
      
       
      <CssBaseline />

     
      <Container id="container" className="Container" maxWidth="false">
      
        <Typography className="Typography" style={{  minHeight: '3000px'}} > {/*1760px */}

        
        <HeadScroll/>
        <hr width="100%" style={{border:'1px solid gray'}}></hr>
        <Navbar/>
        <hr width="100%" style={{border:'1px solid gray'}}></hr>
         <p className="ABO">ABO</p>
        <MenuIcon className="HamburgerMenu"/>
        
        <div align="center">
       
        
        <div className="DivLogo">
       
        <img  className="Logo" src={mainlogo} alt="logo" />
        </div>
    </div>
    
  
<hr width="100%" className={classes.line}>
  </hr>




<Navbarfoot/>

<div className='MainArticle'>
<div className='ArticleText'>
<div className='ArticleDesr'>
{DataNew.articles[0].description}
</div>
<div className='ArticleContent'>
{DataNew.articles[0].content}
<span style={{color: 'orange', marginLeft: '5px'}}>Mehr...</span>
<br></br>
<p style={{color:'gray', lineHeight:0, fontStyle:'oblique'}}>Von {DataNew.articles[0].author}</p>
</div>

<div className='ArticleIcons'>
<div className='IconandText'><ShareIcon style={{color:'grey', fontSize:'15px'}}/>Teilen</div>
<div className='IconandText'><TurnedInIcon style={{color:'grey', fontSize:'15px'}}/>Merken</div>
<div className='IconandText'><ChatBubbleIcon style={{color:'grey', fontSize:'15px'}}/>1 Kommentar</div>
</div>
</div>


<div className='ArticleImg'>
<img src={DataNew.articles[0].urlToImage}/>
</div>


</div>

<hr width="100%"></hr>

<div className='ThreeArticlesTakeover'>
<ThreeArticles/>

</div>

<div style={{width:"100%", height:'55px'}}></div>
<div style={{textTransform:'uppercase',fontWeight:900,fontSize:'20px',cursor:'pointer'}}>Leserfavoriten <span style={{textTransform:'uppercase',fontWeight:900,fontSize:'15px',float:'right'}}>Mehr exklusive beiträge {`>`}</span></div>

<hr width="100%" style={{border:'3px solid black'}}></hr>
<div style={{width:"100%", height:'55px'}}></div>

<div className='ThreeArticlesTakeover'>
<ThreeArticles2/>

</div>


<div style={{width:"100%", height:'55px'}}></div>
<div style={{textTransform:'uppercase',fontWeight:900,fontSize:'20px',cursor:'pointer'}}>Meine News <span style={{textTransform:'uppercase',fontWeight:900,fontSize:'15px',float:'right'}}>weiter zu meine news {`>`}</span></div>

<hr width="100%" style={{border:'3px solid black'}}></hr>



<Buttonsgroup/>
<div style={{width:"100%", height:'55px'}}></div>
<hr width="65%" style={{float:'left'}}></hr>
<div style={{width:"100%", height:'35px'}}></div>

<div style={{display:'flex'}}>
<MainArticles/>

<SideBar/>
</div>


<div style={{width:"100%", height:'35px'}}></div>

<div style={{width:'80%', height:'500px'}}>
  <iframe style={{width:'inherit', height:'inherit', pointerEvents:'none'}} src="https://midgard.pressekompass.net/compasses/handelsblatt/handelsblattumfrage-wird-der-dax-heute-f-ibnM?bifrost=true"></iframe>
</div>

<div style={{width:"100%", height:'35px'}}></div>

<div style={{textTransform:'uppercase',fontWeight:900,fontSize:'20px',cursor:'pointer'}}>Exklusiv für Abonnenten <span style={{textTransform:'uppercase',fontWeight:900,fontSize:'15px',float:'right'}}>Mehr exklusive beiträge {`>`}</span></div>

<hr width="100%" style={{border:'3px solid black'}}></hr>
<div style={{width:"100%", height:'35px'}}></div>

<div className='ThreeArticlesTakeover'>
<ThreeArticles3/>
</div>

<div style={{width:"100%", height:'35px'}}></div>
<div style={{textTransform:'uppercase',fontWeight:900,fontSize:'20px',cursor:'pointer'}}>Exklusiv <span style={{textTransform:'uppercase',fontWeight:900,fontSize:'15px',float:'right'}}>Mehr exklusive beiträge {`>`}</span></div>

<hr width="100%" style={{border:'3px solid black'}}></hr>
<div style={{width:"100%", height:'35px'}}></div>

<div className='ThreeArticlesTakeover'>
<ThreeArticles4/>
</div>


<div style={{width:"100%", height:'35px'}}></div>
<hr width="100%"></hr>
<div style={{width:"100%", height:'35px'}}></div>

<div>
  <Links/>
</div>

<div style={{width:"100%", height:'55px'}}></div>
<div>
<Footer/>
</div>
<div style={{width:"100%", height:'55px'}}></div>
  </Typography>

  
 
      </Container>

      
      
      
     
    </div>
 
  );}

  
  



export default Takeover;